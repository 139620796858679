import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import { HeaderContainer,LogoSection,LogoImage,LogoText } from "./header.styles";

export const Header =()=>{
    return (
      <HeaderContainer paddingVertical="0" boxedLayout>
        <LogoSection paddingVertical="0" boxedLayout>
          <Link to="/" className="Link">
            <LogoImage src={Logo} alt="logo" />
            <LogoText>Linked Wallets</LogoText>
          </Link>
        </LogoSection>
      </HeaderContainer>
    );
};